import * as React from "react";

//import "../assets/plugins/nucleo/css/nucleo.css";
//import "@fortawesome/fontawesome-free/css/all.min.css";
import "../assets/scss/argon-dashboard-react.scss";
import PrimaryNavbar from "../components/Navbars/PrimaryNavbar";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Collapse,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import ArrowText from "../components/ArrowText";
import { Helmet } from "react-helmet";
import { Loader } from "@googlemaps/js-api-loader";

import appleIcon from "../assets/img/favicons/apple-touch-icon.png";
import fav16 from "../assets/img/favicons/favicon.png";
import fav32 from "../assets/img/favicons/favicon.png";
import PrimaryFooter from "../components/Footers/PrimaryFooter";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const isBrowser = typeof window !== "undefined";

// markup
const LocationsPage = () => {
  if (isBrowser) {
    if (window?.location?.pathname?.includes("locations/mt.-laurel-nj")) {
      window.location.href = "/locations/mt-laurel-nj";
    }
  }

  return (
    <>
      <Helmet>
        <title>404 Page Not Found - Project LeanNation</title>
        <meta
          name="description"
          content="Project LeanNation is a nutrition company dedicated to educating, supporting, and creating better health through prepared healthy meals. Join The Nation!"
        ></meta>
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="https://projectleannation.com/404" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="404 Page Not Found - Project LeanNation"
        />
        <meta property="og:url" content="https://projectleannation.com/404" />
        <meta property="og:site_name" content="Project LeanNation" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/PLNRochester/"
        />
        <meta
          property="article:modified_time"
          content="2021-01-07T13:57:12+00:00"
        />
        <meta
          property="og:image"
          content="https://s3.amazonaws.com/cdn.projectleannation.com/assets/public-site/seo_primary_banner.jpg"
        />
        <meta property="og:image:width" content="1080" />
        <meta property="og:image:height" content="650" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@poweredbyPLN" />
        <meta name="twitter:site" content="@poweredbyPLN" />
        <meta name="twitter:label1" content="Written by" />
        <meta name="twitter:data1" content="Tim D" />
        <meta name="twitter:label2" content="Est. reading time" />
        <meta name="twitter:data2" content="3 minutes" />
        <link rel="apple-touch-icon" sizes="180x180" href={appleIcon} />
        <link rel="icon" type="image/png" sizes="32x32" href={fav32} />
        <link rel="icon" type="image/png" sizes="16x16" href={fav16} />
      </Helmet>
      <PrimaryNavbar></PrimaryNavbar>
      <div className="mt-5 mt-lg-6"></div>
      <div className="bg-white">
        <div className="py-6 py-lg-7">
          <Container>
            <div className="text-center py-6 py-lg-7">
              <h3
                className="display-3 text-secondary family-karla mb-0"
                style={{ lineHeight: 1.2 }}
              >
                Oops! We couldn't find this page.
              </h3>
              <p className="lead family-os text-muted mt-3">
                Well this is a little bit embarrassing... We couldn't find the
                page you're looking for. In the meantime, let's get you back
                home.
              </p>
              <Link to="/">
                <Button color="primary" className="mt-4">
                  Return Home <ArrowText></ArrowText>
                </Button>
              </Link>
            </div>
          </Container>
        </div>
      </div>
      <PrimaryFooter></PrimaryFooter>
    </>
  );
};

export default LocationsPage;
