/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import ArrowText from "../../components/ArrowText";
import React from "react";

// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Form,
} from "reactstrap";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import API from "../../lib/API";

let logo = require("../../assets/img/brand/pln_primary_no_dot.svg");

class PrimaryFooter extends React.Component {
  state = {
    email: "",
  };

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  }

  getConversionCard() {
    const url = typeof window !== "undefined" ? window.location.href : "";

    if (url?.includes("franchising")) {
      return (
        <div className="pb-0 pt-5 pt-lg-5 pb-lg-3 bg-white">
          <Container fluid>
            <Row className="justify-content-center">
              <Col xs="12" sm="12" md="12" lg="11">
                <Card className="bg-primary shadow">
                  <CardBody>
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col xs="12" md="">
                          <h1 className="display-3 text-white">
                            Ready To Take The Next Step?
                          </h1>
                          <p className="lead text-white mt-2 mb-0">
                            Partner with Project LeanNation today!
                          </p>
                        </Col>
                        <Col xs="12" md="auto" className="mt-4 mt-md-0">
                          <a
                            onClick={() => {}}
                            href="https://calendly.com/pln-franchise-development/30min"
                            target="_blank"
                          >
                            <Button
                              color="white"
                              className="text-uppercase text-primary"
                              size="lg"
                              block
                              style={{ fontSize: "1.2rem" }}
                            >
                              Schedule A Call <ArrowText></ArrowText>
                            </Button>
                          </a>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }

    return (
      <div className="pb-0 pt-5 pt-lg-5 pb-lg-3 bg-white">
        <Container fluid>
          <Row className="justify-content-center">
            <Col xs="12" sm="12" md="11" lg="10">
              <Card className="bg-primary shadow">
                <CardBody>
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col xs="12" md="">
                        <h1 className="display-3 text-white">
                          Ready To Crush Your Goals?
                        </h1>
                        <p className="lead text-white mt-2 mb-0">
                          Kickstart your healthy lifestyle today!
                        </p>
                      </Col>
                      <Col xs="12" md="auto" className="mt-4 mt-md-0">
                        <a
                          onClick={() => {}}
                          href="https://account.projectleannation.com/start"
                        >
                          <Button
                            color="white"
                            className="text-uppercase text-primary"
                            size="lg"
                            block
                            style={{ fontSize: "1.2rem" }}
                          >
                            Get Started <ArrowText></ArrowText>
                          </Button>
                        </a>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  render() {
    let now = new Date();

    let currentYear = now.getFullYear();

    return (
      <>
        {this?.props?.conversion !== false && this.getConversionCard()}

        <footer className="py-5 bg-white">
          <Container fluid className="mt-5">
            <Row className="justify-content-center">
              <Col xs="12" md="11" lg="10">
                <Row>
                  <Col
                    lg="4"
                    md="12"
                    sm="12"
                    className="mb-5 text-center text-lg-left"
                  >
                    <img
                      src={logo}
                      alt="Project LeanNation logo"
                      style={{ width: "90%", maxWidth: 220 }}
                    ></img>
                    <p className="text-muted mt-4">
                      We consider your results our responsibility.
                    </p>
                    <Row className="justify-content-center justify-content-lg-start">
                      <Col xs="auto" className="pr-0">
                        <a
                          href="https://instagram.com/projectleannation"
                          target="_blank"
                        >
                          <svg
                            style={{
                              height: "30px",
                              width: "30px",
                            }}
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"
                            />
                          </svg>
                        </a>
                      </Col>
                      <Col xs="auto">
                        <a
                          href="https://www.facebook.com/PLNRochester/"
                          target="_blank"
                        >
                          <svg
                            style={{
                              width: "30px",
                              height: "30px",
                            }}
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z"
                            />
                          </svg>
                        </a>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    lg="2"
                    md="6"
                    sm="12"
                    className="text-center text-lg-left mb-5"
                  >
                    <h3 className="text-dark mb-3">Company</h3>
                    <div>
                      <p className="mb-1">
                        <NavLink className="p-0" href="/locations" tag={Link}>
                          Locations
                        </NavLink>
                      </p>
                      <p className="mb-1">
                        <NavLink className="p-0" href="/franchising" tag={Link}>
                          Franchising
                        </NavLink>
                      </p>
                      <p className="mb-1">
                        <NavLink
                          className="p-0"
                          href="/join-the-team"
                          tag={Link}
                        >
                          Join The Team
                        </NavLink>
                      </p>
                      <p className="mb-1">
                        <NavLink className="p-0" href="/affiliates" tag={Link}>
                          Affiliates
                        </NavLink>
                      </p>
                      <p className="mb-1">
                        <NavLink className="p-0" href="/ambassadors" tag={Link}>
                          Ambassadors &amp; Influencers
                        </NavLink>
                      </p>
                    </div>
                  </Col>
                  <Col
                    lg="2"
                    md="6"
                    sm="12"
                    className="mb-5 text-center text-lg-left"
                  >
                    <h3 className="text-dark mb-3">Resources</h3>
                    <div>
                      <p className="mb-1">
                        <NavLink className="p-0" href="/faq" tag={Link}>
                          FAQ
                        </NavLink>
                      </p>
                      <p className="mb-1">
                        <NavLink className="p-0" href="/blog" tag={Link}>
                          Blog
                        </NavLink>
                      </p>
                      <p className="mb-1">
                        <NavLink className="p-0" href="/outreach" tag={Link}>
                          Contact Us
                        </NavLink>
                      </p>
                      <p className="mb-1">
                        <NavLink
                          className="p-0"
                          href="https://www.iubenda.com/privacy-policy/76912715"
                          target="_blank"
                        >
                          Privacy
                        </NavLink>
                      </p>
                      <p className="mb-1">
                        <NavLink
                          className="p-0"
                          href="https://www.iubenda.com/terms-and-conditions/76912715"
                          target="_blank"
                        >
                          Terms
                        </NavLink>
                      </p>
                    </div>
                  </Col>
                  <Col
                    lg="4"
                    md="12"
                    sm="12"
                    className="mb-4 text-center text-lg-left"
                  >
                    <h3 className="text-dark mb-3">Newsletter</h3>
                    <p className="text-muted">
                      Subscribe to our email newletter to stay in the loop with
                      our latest updates &amp; offers!
                    </p>
                    <div
                      className="d-inline-block mt-2"
                      style={{ width: "100%", maxWidth: 400 }}
                    >
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();

                          this.setState({
                            newsletter: true,
                          });

                          API.signupForNewsletter(this.state.email)
                            .then(() => {})
                            .finally(() => {
                              this.setState({
                                newsletter: false,
                                newsletterSuccess: true,
                                email: "",
                              });

                              setTimeout(() => {
                                this.setState({
                                  newsletterSuccess: false,
                                });
                              }, 4000);
                            });
                        }}
                      >
                        <Row>
                          <Col xs="" className="pr-0">
                            <Input
                              className="form-control"
                              type="email"
                              required={true}
                              placeholder="you@projectleannation.com"
                              aria-label="Your Email"
                              value={this.state.email}
                              onChange={this.handleInputChange.bind(this)}
                              name="email"
                              aria-describedby="button-addon2"
                            />
                          </Col>
                          <Col xs="auto">
                            <Button
                              color="primary"
                              id="button-addon2"
                              type="submit"
                              disabled={
                                this.state.newsletter ||
                                this.state.newsletterSuccess ||
                                !this.state.email
                              }
                            >
                              Join
                            </Button>
                          </Col>
                        </Row>
                        {this.state.newsletterSuccess ? (
                          <>
                            <p className="small mt-3">
                              Thanks for subscribing!
                            </p>
                          </>
                        ) : null}
                      </Form>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </footer>

        <footer className="pt-0 pb-5 bg-white">
          <Container fluid>
            <Row className="justify-content-center">
              <Col xs="12" md="11" lg="10">
                <p className="small text-muted mb-0 text-center text-lg-left">
                  © {currentYear} Project LeanNation Franchising. All Rights
                  Reserved.
                </p>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default PrimaryFooter;
